<script setup lang="ts">
import queryDataEntries from '~~/graphql/queries/Resources/ResourcesEntries.query.graphql'
import { useUserStore } from '~~/stores/user'
import { storeToRefs } from 'pinia'
import {
  PopoverArrow,
  PopoverClose,
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
} from 'radix-vue'

const userStore = useUserStore()
const { readingList } = storeToRefs(userStore)

const showModal = ref(false)

const options = reactive({
  watch: readingList.value,
})

const { result, loading, error, refetch } = useQuery(queryDataEntries, {
  filter: {
    id: {
      in: readingList.value,
    },
  },
})

const filteredData = computed(() => result.value?.entries ?? [])

userStore.$subscribe((mutation, state) => {
  refetch({
    filter: {
      id: {
        in: readingList.value,
      },
    },
  })
})

const triggerClass = computed(() => ({
  'inline-flex size-[35px] cursor-default items-center justify-center rounded-full outline-none':
    true,
  'bg-gray-50/20': readingList.length === 0,
  'shadow-blackA7 bg-white shadow-[0_2px_10px] focus:shadow-[0_0_0_2px] focus:shadow-black':
    readingList.length > 0,
}))

const iconClass = computed(() => ({
  'text-dark/50': readingList.length === 0,
  'text-black': readingList.length > 0,
}))
</script>

<template>
  <PopoverRoot @update:open="refetch">
    <PopoverTrigger
      class="inline-flex size-[35px] cursor-default items-center justify-center rounded-full outline-none"
      :class="triggerClass"
      aria-label="Readinglist"
    >
      <Icon
        :class="iconClass"
        class="size-6"
        name="fluent:reading-list-20-regular"
      />
    </PopoverTrigger>
    <PopoverPortal>
      <PopoverContent
        side="bottom"
        :side-offset="5"
        class="z-[100] w-[260px] rounded bg-white p-5 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade"
      >
        <div class="flex flex-col gap-2.5">
          <p
            class="text-mauve12 mb-2.5 text-[15px] font-semibold leading-[19px]"
          >
            Readinglist ({{ readingList.length }})
          </p>
          <template v-if="!loading && result">
            {{ result.entries.data }}
            {{ readingList }}
            <fieldset
              v-for="item in result.value"
              :key="item"
              class="flex items-center gap-5"
            >
              <label class="text-grass11 text-[13px]" for="width">
                Item: {{ item }}
                <Icon
                  name="radix-icons:cross-2"
                  @click="userStore.removeEntryfromReadingList(item)"
                />
              </label>
            </fieldset>
          </template>
        </div>

        <PopoverClose
          class="absolute right-[5px] top-[5px] inline-flex size-[25px] cursor-default items-center justify-center rounded-full outline-none focus:shadow-[0_0_0_2px]"
          aria-label="Close"
        >
          <Icon name="radix-icons:cross-2" />
        </PopoverClose>
        <PopoverArrow class="fill-white" />
      </PopoverContent>
    </PopoverPortal>
  </PopoverRoot>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
</template>
